import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const WWGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page wuthering'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Guides</h1>
          <h2>
            Best guides for Wuthering Waves that will help you understand the
            game better.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/wuthering-waves/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/wuthering-waves/guides/reroll"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/wuthering-waves/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_beginner.jpg"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Tips and tricks"
          link="/wuthering-waves/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_tips.png"
              alt="Tips and tricks"
            />
          }
        />
        <CategoryCard
          title="Beginner events and rewards"
          link="/wuthering-waves/guides/beginner-events"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_events.jpg"
              alt="Beginner events and rewards"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/wuthering-waves/guides/free-characters"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_freechars.jpg"
              alt="Free characters"
            />
          }
        />
      </Row>
      <SectionHeader title="Generic guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Battle Pass Weapons"
          link="/wuthering-waves/guides/battle-pass-weapons"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_bp.jpg"
              alt="Battle Pass Weapons"
            />
          }
          isWIP
        />
        <CategoryCard
          title="Echo System"
          link="/wuthering-waves/guides/echoes-explained"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_echo.png"
              alt="Echo system explained"
            />
          }
        />
        <CategoryCard
          title="Echo Sets"
          link="/wuthering-waves/guides/echo-sets"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_echo4.png"
              alt="Echo sets"
            />
          }
        />
        <CategoryCard
          title="Echo Stats"
          link="/wuthering-waves/guides/echo-stats"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_echo3.png"
              alt="Echo stats"
            />
          }
        />
        <CategoryCard
          title="Characters Stats"
          link="/wuthering-waves/characters-stats"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_charstats.jpg"
              alt="Characters Stats"
            />
          }
        />
        <CategoryCard
          title="Gacha System"
          link="/wuthering-waves/guides/gacha"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_gacha.jpg"
              alt="Gacha system"
            />
          }
        />
        <CategoryCard
          title="Game Modes"
          link="/wuthering-waves/guides/game-modes"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_modes.jpg"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Game Systems"
          link="/wuthering-waves/guides/game-systems"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_systems.jpg"
              alt="Game Systems"
            />
          }
        />
        <CategoryCard
          title="Resonators Explained"
          link="/wuthering-waves/guides/resonators"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_reso.jpg"
              alt="Resonators"
            />
          }
        />
        <CategoryCard
          title="Resonator Progression"
          link="/wuthering-waves/guides/resonator-progression"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_reso2.jpg"
              alt="Resonator Progression"
            />
          }
        />
      </Row>
      <SectionHeader title="Database" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Interactive Map"
          link="https://www.ghzs666.com/wutheringwaves-map#/"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_map.jpg"
              alt="Map"
            />
          }
          outsideLink
        />
        <CategoryCard
          title="Echo list"
          link="/wuthering-waves/echoes"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_echo2.png"
              alt="Echo list"
            />
          }
        />
        <CategoryCard
          title="Weapons list"
          link="/wuthering-waves/weapons"
          image={
            <StaticImage
              src="../../../images/ww/categories/category_weapons.png"
              alt="Weapons list"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default WWGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Wuthering Waves | Prydwen Institute"
    description="Best guides for Wuthering Waves that will help you understand the game better."
    game="ww"
  />
);
